body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.miDialogo .MuiPaper-root {
  background-color: #647cf8;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 50px;
  border-radius: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.center-screen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
  width: 100vw;
}

.spin {
  position: absolute; /* Añade esta línea */
  top: 50%; /* Añade esta línea */
  left: 50%; /* Añade esta línea */
  transform: translate(-50%, -50%); /* Añade esta línea */
  animation: spin 2s linear infinite;
  width: 100px;
  height: 100px;
}


@media (max-width: 600px) {
  .center-screen {
    margin-top: 130%;
  }
}