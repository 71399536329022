.App{
  background: #f4f3f2;
  text-align: left;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}



.material-symbols-outlined {
  font-size: 2rem;
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' 0,
  'opsz' 48
}

h1, h2, h3, h4, h5, h6{
  font-weight: 700;
}

.contetilebotonpri{
  display: block;
  padding: 2rem 0;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}


.tilebotonpri{
  position: relative;
  display: inline-block;
  width: 15.5rem;
  height: 14rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 10%);
  user-select: none;
  cursor: pointer;
  transition: all ease 0.1s;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}

@media (max-width: 617px) {
  .tilebotonpri{
    width: 100%;
  }
}

.tilebotonpri:hover{
  box-shadow: 0px 5px 6px rgb(0 0 0 / 10%);
  background-color: #f5f5f5;
  transform: scale(1.01);
}

.tilebotonpri:active{
  transform: scale(0.96);
}

.tilebotonpri-tit{
  position: absolute;
  font-weight: bold;
  font-size: 1.5rem;
  top: 6rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  transition: all ease 0.2s;
  text-transform: capitalize;
}

.tilebotonpri:hover .tilebotonpri-tit{
  color: rgb(235 145 128);
  color: #4c74e9;
}

.tilebotonpri-desc{
  position: absolute;
  font-size: 0.92rem;
  top: 8.5rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.2;
  padding-right: 5rem;
}

.tilebotonpri-estado{
  position: absolute;
  top: 13rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
}

.tilebotonpri-icon{
  transition: all ease 0.5s;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  width: 7rem;
  height: 7rem;
}

.tilebotonpri.disabled{
  color: silver;
  opacity: 0.5;
  cursor: default;
}

.tilebotonpri.disabled:hover{
  color: silver;
  transform: scale(1);
  background: #ffffff;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 10%);
}

.tilebotonpri.disabled:active{
  color: silver;
  transform: scale(1);
}

.tilebotonpri.disabled .tilebotonpri-icon{
  filter: saturate(0);
  opacity: 0.5;
}

.tilebotonpri.disabled:hover .tilebotonpri-tit{
  color: silver;
}


.barraapp{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}


.barraapp-btnsder{
  display: inline-block;
  text-align: right;
}







.contelistadogrande{
  display: block;
  padding: 2rem 0;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}


.listadogrande{
  position: relative;
  display: inline-block;
  width: 25rem;
  height: 14rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 10%);
  user-select: none;
  cursor: pointer;
  transition: all ease 0.1s;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
}

@media (max-width: 900px) {
  .listadogrande{
    width: 100%;
  }
}

.listadogrande-tit{
  position: absolute;
  font-weight: bold;
  font-size: 1.5rem;
  top: 2rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  transition: all ease 0.2s;
}

.listadogrande-data{
  position: absolute;
  font-size: 0.92rem;
  top: 5rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.2;
  padding-right: 5rem;
}

.listadogrande-data-ele{
  display: block;
  margin-right: 1rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
}

.listadogrande-data-ele label{
  display: inline-block;
  width: 6rem;
}

.bloqueprestamopdt{
  background: #4c74e9;
  border-radius: 1rem;
  color: #FFFFFF;
  margin-top: 2rem;
  padding: 3rem 2rem;
  background: linear-gradient(144deg, rgba(76,116,233,1) 0%, rgb(79, 76, 233) 100%);
  position: relative;
  overflow: hidden;
}

.bloqueprestamopdt > .iconodefondogrande{
  position: absolute;
  color: #0a1f4d;
  top: -16rem;
  left: -15rem;
  opacity: 0.1;
  font-size: 40rem;
  transform: rotate(40deg);
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
}

.bloqueprestamopdt-tit{
  position: relative;
  z-index: 5;
  text-align: center;
  line-height: 1;
}

.bloqueprestamopdt-tit > span{
  display: block;
  font-size: 1rem;
  padding-bottom: 0.4rem;
  color: rgba(255, 255, 255, 0.6);
}

.bloqueprestamopdt-tit > h4{
  font-size: 3rem;
  margin: 0;
  padding: 0;
  line-height: 1;
  padding-bottom: 2rem;
}


.bloqueprestamopdt-tit > h4 > span{
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.bloqueprestamopdt-bar{
  position: relative;
  z-index: 5;
  display: block;
  border-radius: 100rem;
  border: 1px solid #ffffff73;
  box-shadow: inset 0px 4px 4px #00000021;
  background: #0000000a;
  overflow: hidden;
}

.bloqueprestamopdt-barllena{
  width: 1%;
  display: block;
  background: linear-gradient(90deg, #fb907ba1 0%, #ff9280e8 100%);
  height: 3rem;
  border-radius: 10rem;
}

.bloqueprestamopdt-barstatus{
  z-index: 5;
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1;
  transform: translate(-50%, -50%);
}

.bloqueprestamopdt-dtll{
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.bloqueprestamopdt-dtll > div{
  border: 1px solid #ffffff73;
  color: rgba(255, 255, 255, 0.7);
}

.capitalize{
  text-transform: capitalize;
}





.tarjetaperfil{
  background: #4c74e9;
  border-radius: 1rem;
  color: #FFFFFF;
  margin-top: 2rem;
  padding: 3rem 2rem;
  background: linear-gradient(144deg, rgba(76,116,233,1) 0%, rgb(79, 76, 233) 100%);
  position: relative;
  overflow: hidden;
}

.tarjetaperfil > .material-symbols-outlined{
  position: absolute;
  color: #0a1f4d;
  top: -15rem;
  right: -5rem;
  opacity: 0.1;
  font-size: 40rem;
  transform: rotate(323deg);
  -webkit-user-select: none;
  user-select: none;
}

.tarjetaperfil-p1{
 display: inline-block;
 vertical-align: middle;
}

.tarjetaperfil-p2{
 display: inline-block;
 vertical-align: middle;
}


.listitempagostatus1, .listitempagostatus6{
  text-decoration: line-through !important;
  color: silver !important;
}

.listitempagostatus1 .MuiTypography-root, .listitempagostatus6 .MuiTypography-root{
  text-decoration: line-through !important;
  color: silver !important;
}


.listitempagostatus3{
  color: rgb(228, 77, 77) !important;
}

.listitempagostatus3 .MuiTypography-root{
  color: rgb(228, 77, 77) !important;
}

.listitempagostatus4{
  color: rgb(228, 77, 77) !important;
}

.listitempagostatus4 .MuiTypography-root{
  color: rgb(228, 77, 77) !important;
}


.listahistorialestado4{
  text-decoration: line-through !important;
  color: silver !important;
}

.listahistorialestado4 .MuiTypography-root{
  text-decoration: line-through !important;
  color: silver !important;
}

.listahistorialestado2{
  text-decoration: line-through !important;
  color: silver !important;
}

.listahistorialestado2 .MuiTypography-root{
  text-decoration: line-through !important;
  color: silver !important;
}

.listahistorialestado7{
  text-decoration: line-through !important;
  color: silver !important;
}

.listahistorialestado7 .MuiTypography-root{
  text-decoration: line-through !important;
  color: silver !important;
}


.imgprevperfil{
  display: block;
  width: auto;
  border-radius: 0.5rem;
  max-height: 10rem;
  display: block;
  margin: 0 auto;
}